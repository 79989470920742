import React from 'react';
import { graphql } from 'gatsby';
import Post, { PostProps } from '@components/post';

const WhitePaperPostPage = ({ data }: PostProps): JSX.Element => <Post data={data} />;

export default WhitePaperPostPage;

export const query = graphql`
  query WhitePaperPostQuery($uid: String, $categoriesArray: [String]) {
    prismicGeneralConfig {
      data {
        favicon {
          url
        }
      }
    }
    prismicPostPageConfig {
      ...PostPageConfigFragment
    }
    currentPost: allPrismicWhitePaperPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          ...WhitePaperPostFragment
        }
      }
    }
    relativePosts: allPrismicWhitePaperPost(
      filter: { tags: { in: $categoriesArray }, uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...WhitePaperPostPreviewFragment
        }
      }
    }
    restPosts: allPrismicWhitePaperPost(
      filter: { uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...WhitePaperPostPreviewFragment
        }
      }
    }
  }
`;
